import { render, staticRenderFns } from "./ParaSettings.vue?vue&type=template&id=46acdeec&scoped=true&"
import script from "./ParaSettings.vue?vue&type=script&lang=js&"
export * from "./ParaSettings.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "46acdeec",
  null
  
)

/* custom blocks */
import block0 from "./ParaSettings.vue?vue&type=custom&index=0&blockType=i18nf"
if (typeof block0 === 'function') block0(component)

export default component.exports